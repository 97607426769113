<template>
  <div class="setUp">
    <!-- <div class="title">信任与保障：</div>
    <div class="detail">
      <div ref="contentRef" class="content" :style="{height: showHeight}" v-html="articleDetail.guarantee"></div>
    </div>
    <div class="title">用户协议：</div>
    <div class="detail">
      <div ref="contentRef" class="content" :style="{height: showHeight}" v-html="articleDetail.user_agreement"></div>
    </div> -->
    <div class="title">帮助中心：</div>
    <div class="detail">
      <div ref="contentRef" class="content" :style="{height: showHeight}" v-html="articleDetail.help_center"></div>
    </div>
  </div>
</template>

<script>
import {system} from '@/api/common/public'
export default {
  name: 'setUp',
  data() {
    return {
      articleDetail: {},
      showHeight: ''
    }
  },
  mounted() {
    // this.initHeight()
    this.systemModel()
  },
  methods: {
    systemModel() {
      const data = {
        key: 'guarantee,user_agreement,help_center'
      }
      system(data).then(res => {
        this.articleDetail = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .setUp{
    padding: 32px;
    background: #fff;
    margin-top: 12px;
    min-height: 582px;
    .detail{
      // margin-top: 25px;
      position: relative;
      .content{
        transition: all .3s ease-in-out 0s;
        overflow: hidden;
      }
    }
  }
</style>
